import React from "react"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import * as BlockContent from "@sanity/block-content-to-react"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import SeparatorLine from "../elements/separatorLine"
import Button from "../elements/button"
import { StyledLink } from "../shared/styledElements"
import SEO from "../components/seo.js"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

const BookReviewPreviews = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityBookPreviewList {
        edges {
          node {
            bookpreviewListHeadline
            category
            _rawPreviewListText
          }
        }
      }
      allSanityBookreview {
        edges {
          node {
            slug {
              current
            }
            booktitle
            subheadline
            year
            publisher
            editor
            artist
            bookImages {
              image_bookReview {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            _rawPreviewtextBook
            _rawReviewText
            titleImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const localDescription = `Buchrezensionen zu Fotobüchern aus den Bereichen der Street Photography und der Kunstfotografie. Für interessierte Sammler von Fotobüchern.`

  return (
    <Layout>
      <SEO
        title="Fotobücher der Street Photography und analoger Fotografie"
        description={localDescription}
      />
      <Body>
        <div>
          {/* Header */}
          {/* Hier im Header etwas nervig - Graphql gibt die Daten nur als Array mit 2 Einträgen frei, wobei einer immer leer ist */}
          {data.allSanityBookPreviewList.edges && (
            <div>
              <Headline>
                <FirstRow>
                  <span>
                    {data.allSanityBookPreviewList.edges[0].node.category ===
                    null
                      ? data.allSanityBookPreviewList.edges[1].node.category
                      : data.allSanityBookPreviewList.edges[0].node.category}
                  </span>{" "}
                  <StyledLink aria-label="zurück zur Startseite" to="/">
                    ZUR STARTSEITE
                  </StyledLink>
                </FirstRow>
                <h1>
                  {data.allSanityBookPreviewList.edges[0].node
                    .bookpreviewListHeadline === null
                    ? data.allSanityBookPreviewList.edges[1].node
                        .bookpreviewListHeadline
                    : data.allSanityBookPreviewList.edges[0].node
                        .bookpreviewListHeadline}
                </h1>
              </Headline>
              <PreviewText>
                <BlockContent
                  blocks={
                    data.allSanityBookPreviewList.edges[0].node
                      ._rawPreviewListText === null
                      ? data.allSanityBookPreviewList.edges[1].node
                          ._rawPreviewListText
                      : data.allSanityBookPreviewList.edges[0].node
                          ._rawPreviewListText
                  }
                  serializers={{ types: { block: BlockRenderer } }}
                />
              </PreviewText>
            </div>
          )}
          <SeparatorLine />
        </div>

        {/* Liste */}
        <div>
          {data.allSanityBookreview === null
            ? ""
            : data.allSanityBookreview.edges.map((entry, index) => (
                <PreviewContainer key={"BookPreview" + index}>
                  <div>
                    <Section>
                      {entry.node.titleImage && (
                        <StyledPicture>
                          <a href={entry.node.slug.current}>
                            <Image
                              fluid={
                                entry.node.titleImage.asset
                                  ? entry.node.titleImage.asset.fluid
                                  : null
                              }
                            />
                          </a>
                        </StyledPicture>
                      )}
                      <Metadata>
                        <BookTitle>{entry.node.booktitle}</BookTitle>
                        <Artist>{entry.node.artist}</Artist>
                        <div>{entry.node.year}</div>
                        <div>{entry.node.publisher}</div>
                        <div>{entry.node.editor}</div>
                      </Metadata>
                    </Section>

                    <PreviewText>
                      <BlockContent
                        blocks={entry.node._rawPreviewtextBook}
                        serializers={{ types: { block: BlockRenderer } }}
                      />
                      <LinkContainer>
                        <Button
                          title={"zur Buchrezension " + entry.node.booktitle}
                          ariaLabel={"zur Rezension " + entry.node.booktitle}
                          linkTo={
                            entry.node.slug
                              ? "/bookreview_overview/" +
                                entry.node.slug.current
                              : null
                          }
                        >
                          zur Rezension
                        </Button>
                      </LinkContainer>
                    </PreviewText>
                    <SeparatorLine />
                  </div>
                </PreviewContainer>
              ))}
        </div>
      </Body>
    </Layout>
  )
}

const Body = styled.div`
  background-color: ${theme.colors.bodyBackground};
  padding: ${theme.spaces.standardPadding};
  color: ${theme.colors.darkFont};
`

const Headline = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  padding-bottom: ${theme.spaces.standardPadding};

  h1 {
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  span {
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    font-weight: lighter;
  }

  @media all and (max-width: 768px) {
    span {
      font-size: calc(2 * ${theme.fontsizes.subBannerMediumScreen});
    }
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`

const PreviewContainer = styled.div`
  margin-top: ${theme.spaces.standardPadding};
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% / 3 * 2);
  margin-left: 1vw;

  font-family: ${theme.fontFamily};
  color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
    margin-bottom: ${theme.spaces.standardPadding};
  }
`

const StyledPicture = styled.div`
  margin-bottom: ${theme.spaces.standardPadding};
  width: calc(100% / 3 * 1 - 1vw);

  @media all and (max-width: 768px) {
    width: 100%;
  }
`

const PreviewText = styled.div`
  width: calc(100% / 3 * 2);
  margin-left: calc(100% / 3 * 1);
  border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};
  text-align: justify;
  padding-left: 1vw;

  @media all and (max-width: 768px) {
    width: 100%;
    margin-left: 0;

    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`

const LinkContainer = styled.div`
  margin-top: ${theme.spaces.standardPadding};
`

const BookTitle = styled.h2`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  font-size: calc(1 * ${theme.fontsizes.h2MediumScreen});

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.h2SmallScreen};
  }
`

const Artist = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  // padding-bottom: ${theme.spaces.standardPadding};
  font-size: ${theme.fontsizes.textMediumScreen};
  font-weight: lighter;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
    margin-bottom: ${theme.spaces.smallSpacing}
  }
`

export default BookReviewPreviews
